.cartItemContainer {
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 600px;
  box-shadow: 0 0px 10px rgba(154, 160, 185, 0.05),
    0 0px 40px rgba(166, 173, 201, 0.2);
  border-radius: 16px;
}
// .cartItemContainer :hover {
//   box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
//     0 15px 40px rgba(166, 173, 201, 0.2), 0 15px 40px rgba(166, 173, 201, 0.2);
// }
.cartItemThumbnail {
  margin-left: 0px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
}
.thumbnailContainer {
  margin-left: 10px;
  margin-right: 30px;
}

.closeSM {
  display: none;
}

.closeMD {
  display: initial;
}

@media screen and (max-width: 700px) {
  .mainContent {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .cartItemContainer {
    padding: 10px;
  }
  .closeSM {
    display: initial;
  }
  .closeMD {
    display: none;
  }
  .thumbnailContainer {
    // margin-left: 40px;
    // margin-right: 40px;
  }
}
@media screen and (max-width: 570px) {
  .cartItemContainer {
    padding: 10px;
  }
  .textCenter {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .thumbnailContainer {
    margin-left: 40px;
    margin-right: 40px;
  }
  .closeSM {
    display: initial;
  }
  .closeMD {
    display: none;
  }
}
