.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.designContainer {
  display: flex;
  flex-direction: row;
  color: white;
  overflow: hidden;
  max-width: 500px;
  border-radius: 8px !important;
  width: 100%;
  max-height: 550;
  height: 100%;
  //   WebkitBoxReflect:
  //     "below 1px linear-gradient(transparent, transparent, #000)";
}
