.orderItemContainer {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 1000px;
}

.orderItemThumbnail {
  margin-left: 0px;
  width: 115px;
  height: 115px;
  object-fit: cover;
}
.thumbnailContainer {
  margin-left: 10px;
}

.closeSM {
  display: none;
}

.closeMD {
  display: initial;
}

@media screen and (max-width: 700px) {
  .mainContent {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .orderItemContainer {
    padding: 50px;
  }
  .closeSM {
    display: initial;
  }
  .closeMD {
    display: none;
  }
  .thumbnailContainer {
    margin-left: 40px;
  }
}
@media screen and (max-width: 570px) {
  .orderItemContainer {
    padding: 10px;
  }
  .textCenter {
    text-align: center;
  }
}
@media screen and (max-width: 320px) {
  .thumbnailContainer {
    margin-left: 20px;
  }
  .closeSM {
    display: initial;
  }
  .closeMD {
    display: none;
  }
}
