.formContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  // width: 500px;
}

.addToCartbtn {
  align-self: center;
  width: 300px;
}

.sizeSelect {
  width: 70%;
}

@media screen and (max-width: 900px) {
  .addToCartbtn {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .sizeSelect {
    width: 100%;
  }
}
@media screen and (max-width: 320px) {
}
