@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap);

a {
  color: inherit; /* blue colors for links too */
  text-decoration: none; /* no underline */
}

.blur {
  backdrop-filter: saturate(200%) blur(30px);
  background-color: hsla(0, 0%, 100%, 0.7) !important;
  box-shadow: inset 0 0 1px 1px hsla(0, 0%, 100%, 0.9),
    0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
  //   box-shadow: 0 20px 27px 0 rgba(0 0 0 / 5%);
}
